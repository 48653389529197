import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;

  @media (max-width: 768px) {
    margin-top: 70px;
    padding: 10px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  max-width: 600px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
  }
`;

const HeroImageWrapper = styled.div`
  width: 100%;
  max-width: 200px; /* Adjust the max-width based on your design */
  margin-bottom: 20px;

  .wrapper {
    display: block;
    position: relative;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid var(--green);
    transition: transform 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      transform: scale(1.05);
    }

    .img {
      position: relative;
      width: 100%;
      height: auto;
      border-radius: 12px;
      transition: filter 0.3s ease;

      &:hover {
        filter: brightness(90%);
      }
    }
  }
`;

const Description = styled.p`
  line-height: 1.6;
`;

const Heading = styled.h1`
  color: var(--green);
  font-size: 36px;
  margin-bottom: 10px;
`;

const StyledHeroLink = styled.a`
  display: inline-block;
  margin: 20px 20px 20px 0;
  color: var(--green);
  text-decoration: none;
  position: relative;

  &:hover,
  &:focus {
    color: var(--pink);
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--green);
    bottom: 0;
    left: 0;
    transform: scaleX(${({ isActive }) => (isActive ? 1 : 0)});
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const Hero = () => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [activeLinkId, setActiveLinkId] = useState(null);

  return (
    <StyledHeroSection>
      <HeroContent>
        <div>
          <Heading>Hi, I'm Dilara.</Heading>
          <CSSTransition in={!prefersReducedMotion} timeout={loaderDelay} classNames="fade">
            <div style={{ transitionDelay: `${prefersReducedMotion ? 0 : navDelay + loaderDelay}ms` }}>
              <Description>
                Software developer with a background in Computer Science and Psychology. Experienced in backend and frontend development. Open-source contributor and skilled Linux user.
              </Description>
            </div>
          </CSSTransition>
          <div className="hero-links">
            <StyledHeroLink
              href="https://github.com/di1ara"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setActiveLinkId('github')}
              isActive={activeLinkId === 'github'}
            >
              GitHub
            </StyledHeroLink>
            <StyledHeroLink
              href="mailto:dilara.yalniz@proton.me"
              onClick={() => setActiveLinkId('email')}
              isActive={activeLinkId === 'email'}
            >
              Email
            </StyledHeroLink>
            <StyledHeroLink
              href="../resume.pdf"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setActiveLinkId('resume')}
              isActive={activeLinkId === 'resume'}
            >
              Resume
            </StyledHeroLink>
          </div>
        </div>
        <HeroImageWrapper>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={400}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Profile Image"
            />
          </div>
        </HeroImageWrapper>
      </HeroContent>
    </StyledHeroSection>
  );
};

export default Hero;
