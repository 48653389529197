import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      {/* Sandglass shape */}
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 20, 80
          L 80, 80
          L 50, 50
          L 80, 20
          L 20, 20
          L 50, 50 z"
      />
      {/* Sand inside the sandglass */}
      <circle cx="50" cy="60" r="5" fill="currentColor" />
      <circle cx="50" cy="40" r="5" fill="currentColor" />
    </g>
  </svg>
);

export default IconLoader;
