import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 700px;
  margin: auto;

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    
  }
`;

const StyledText = styled.div`
  ul.skills-list {
    display: inline-block;
    text-align: left;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '⬨';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }

  h3 {
    margin-top: 30px;
    font-size: var(--fz-lg);
    color: var(--lightest-slate);
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = {
    'Programming Languages': ['Python', 'JavaScript', 'Java', 'C#', 'PHP'],
    'Frameworks': ['Django', 'Flask', 'React', 'Gatsby', 'Bootstrap'],
    'Database Management': ['MySQL', 'MongoDB', 'SQLite', 'PostgreSQL'],
    'Development Methodologies': ['Agile', 'Scrum', 'Kanban'],
    'Project Management Tools': ['Jira', 'Asana'],
    'Testing': ['Manual & Automation Testing', 'Unit Test', 'Selenium', 'Cucumber', 'Pytest'],
  };

  const education = [
    'Computer Programming and Analysis, George Brown College (2021-2024)',
    'Bachelor Degree, Psychology, Mersin University (2016-2021)',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              {`Hello! I'm Dilara, a developer based in Toronto, ON. I'm passionate about learning and solving challenges through creative solutions. 
              When I'm not coding, I enjoy running or practicing yoga for relaxing. One of my greatest joys is traveling to new places, learning about different cultures, and trying new foods.`}
            </p>

            <h3>Technical Skills:</h3>
            <ul className="skills-list">
              {Object.entries(skills).map(([category, categorySkills]) => (
                <li key={category}>
                  <strong>{category}:</strong> {categorySkills.join(', ')}
                </li>
              ))}
            </ul>

            <h3>Education:</h3>
            <ul className="skills-list">
              {education.map((entry, index) => (
                <li key={index}>
                  <strong>Education:</strong> {entry}
                </li>
              ))}
            </ul>
          </div>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default About;