import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledJobsSection = styled.section`
  max-width: 700px;
`;

const StyledTabPanels = styled.div`
  position: relative;
  width: 100%;
  margin-left: 20px;
`;

const StyledTabPanel = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 5px;

  ul {
    ${({ theme }) => theme.mixins.fancyList};
  }

  h3 {
    margin-bottom: 2px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;

    .company {
      color: var(--green);
    }
  }

  .range {
    margin-bottom: 25px;
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
  }
`;

const StyledBottomButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  button {
    ${({ theme }) => theme.mixins.button};
    width: 40px; /* Set a fixed width for small size */
    height: 40px; /* Set a fixed height for small size */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
  }
`;


const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/jobs/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;

  const [activeTabId, setActiveTabId] = useState(0);
  const [tabFocus, setTabFocus] = useState(null);
  const tabs = useRef([]);
  const revealTitle = useRef(null);
  const revealJobs = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealJobs.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const focusTab = () => {
    if (tabs.current[tabFocus]) {
      tabs.current[tabFocus].focus();
      return;
    }
    if (tabFocus >= tabs.current.length) {
      setTabFocus(0);
    }
    if (tabFocus < 0) {
      setTabFocus(tabs.current.length - 1);
    }
  };

  useEffect(() => focusTab(), [tabFocus]);

  const goBack = () => {
    setActiveTabId(Math.max(0, activeTabId - 1));
  };

  const goNext = () => {
    setActiveTabId(Math.min(jobsData.length - 1, activeTabId + 1));
  };

  return (
    <StyledJobsSection id="jobs" >
      <h2 className="numbered-heading" ref={revealTitle}>
        Experience
      </h2>      

      <StyledTabPanels>
        {jobsData &&
          jobsData.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { title, url, company, range } = frontmatter;

            return (
              <CSSTransition key={i} in={activeTabId === i} timeout={250} classNames="fade">
                <StyledTabPanel
                  id={`panel-${i}`}
                  role="tabpanel"
                  tabIndex={activeTabId === i ? '0' : '-1'}
                  aria-labelledby={`tab-${i}`}
                  aria-hidden={activeTabId !== i}
                  hidden={activeTabId !== i}>
                  <h3>
                    <span>{title}</span>
                    <span className="company">
                      &nbsp;@&nbsp;
                      <a href={url} className="inline-link">
                        {company}
                      </a>
                    </span>
                  </h3>
                  <p className="range">{range}</p>
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </StyledTabPanel>
              </CSSTransition>
            );
          })}
      </StyledTabPanels>

      <StyledBottomButtons>
        <button
          className="big-button"
          onClick={goBack}
          disabled={activeTabId === 0}
          aria-label="Go to previous job"
        >
          ◀
        </button>
        <button
          className="big-button"
          onClick={goNext}
          disabled={activeTabId === jobsData.length - 1}
          aria-label="Go to next job"
        >
          ▶
        </button>
      </StyledBottomButtons>

    </StyledJobsSection>
  );
};

export default Jobs;
